import { createGlobalStyle } from 'styled-components'

import {
  colorDarkGrayLightWhite,
  colorDarkSecondaryLightPrimary,
  colorDarkWhiteLightGray,
  transitionizeProperties,
} from './blog'

export const GlobalStyle = createGlobalStyle`
  body {
    color: ${colorDarkWhiteLightGray};
    background-color: ${colorDarkGrayLightWhite};
    ${transitionizeProperties(['color', 'background-color'])};
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: ${colorDarkWhiteLightGray};  
     ${transitionizeProperties(['color'])}
 }
  
  a {
    color: ${colorDarkSecondaryLightPrimary};
    ${transitionizeProperties(['color'])}
  }
`
