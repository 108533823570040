import { createContext } from 'react'

export const modeKey = 'isDark'

const defaultState = {
  [modeKey]: false,
  toggleDark: () => undefined,
}

export const ModeContext = createContext<{
  [modeKey]: boolean
  toggleDark: () => void
}>(defaultState)
