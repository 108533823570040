import type { ReactChild, ReactElement } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { ModeContext, modeKey } from './ModeContext'
import { blog } from './blog'
import { GlobalStyle } from './global-style'

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches

export const ModeProvider = ({
  children,
}: {
  children: ReactChild
}): ReactElement => {
  const [isDark, setIsDark] = useState(false)

  const toggleDark = useCallback(() => {
    setIsDark((prevState) => {
      return !prevState
    })
  }, [])

  useEffect(() => {
    const savedModeKey = window.localStorage.getItem(modeKey)

    if (savedModeKey !== null) {
      const isDarkSavedState: boolean = JSON.parse(savedModeKey)

      setIsDark(isDarkSavedState)
    } else if (supportsDarkMode()) {
      setIsDark(true)
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem(modeKey, JSON.stringify(isDark))
  }, [isDark])

  return (
    <ThemeProvider
      theme={{
        mode: isDark ? 'dark' : 'light',
        ...blog,
      }}
    >
      <ModeContext.Provider
        value={{
          isDark,
          toggleDark,
        }}
      >
        <GlobalStyle />
        {children}
      </ModeContext.Provider>
    </ThemeProvider>
  )
}
