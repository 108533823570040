/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Custom typefaces
import 'typeface-work-sans'
import 'typeface-quattrocento-sans'
import 'prism-theme-night-owl'
import './config/gatsby/prismjs/theme.css'
// import 'prismjs/themes/prism-dark.css'

import React from 'react'

import { ModeProvider } from './config/gatsby/theme/ModeProvider.js'

export const onClientEntry = () => {
  // NOTE: IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === 'undefined') {
    // eslint-disable-next-line
    import('intersection-observer')
    console.log('# IntersectionObserver is polyfilled!')
  }
}

export const wrapRootElement = ({ element }) => {
  return <ModeProvider>{element}</ModeProvider>
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. Reload to display the latest version?',
  )

  if (answer === true) {
    window.location.reload()
  }
}
