import type {
  StandardLonghandPropertiesHyphen,
  StandardShorthandPropertiesHyphen,
} from 'csstype'
import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'
import styledTheme from 'styled-theming'

export const primaryColor = '#8500C7'
const secondaryColor = '#bada55'
const body = 'hsla(0,0%,0%,0.8)'
const header = 'hsla(0,0%,0%,0.9)'
const grayAlpha = '#ccc'
const grayBeta = '#282828'
const marker = '#e4fc5b'

export const blog = {
  breaks: {
    M: '720px',
    L: '900px',
    XL: '1200px',
  },
  breaksHeight: {
    M: '540px',
  },
  color: {
    primary: primaryColor,
    secondary: secondaryColor,
    body,
    header,
    grayAlpha,
    grayBeta: '#282828',
    marker,
  },
  fixedSizes: {
    header: 200,
    footer: 120,
  },
  font: {
    XS: '12px',
    S: '14px',
    M: '16px',
    L: '20px',
    XL: '24px',
    XXL: '30px',
  },
  layers: {
    modal: 200,
    header: 100,
    search: 2,
  },
  multiplier: 4,
}

export const transitionizeProperties = (
  transitionProperties: (
    | keyof StandardLonghandPropertiesHyphen
    | keyof StandardShorthandPropertiesHyphen
  )[],
): FlattenSimpleInterpolation => css`
  transition: ${transitionProperties
    .map((transitionProperty) => `${transitionProperty} 0.2s ease-out`)
    .join(', ')};
`

export const colorDarkWhiteLightGray = styledTheme('mode', {
  dark: 'white',
  light: grayBeta,
})

export const colorDarkGrayLightWhite = styledTheme('mode', {
  dark: grayBeta,
  light: 'white',
})

export const colorDarkSecondaryLightPrimary = styledTheme('mode', {
  dark: secondaryColor,
  light: primaryColor,
})

export const colorDarkPrimaryLightSecondary = styledTheme('mode', {
  dark: primaryColor,
  light: secondaryColor,
})
